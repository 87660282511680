import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { bindActionCreators } from 'redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import { Checkbox, Select2 } from '../../../../../../components/form';
import { generateUserContactsReport } from '../../../../../../redux/actions/reportsActions';
import validate from './validations';
import '../../../../reportsModal.scss';

class UserContactsReportModal extends Component {
  constructor(props) {
    super(props);

    this.handleSubmitClicked = this.handleSubmitClicked.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleSubmitClicked(data) {
    // remove check_all from data
    const { check_all, ...rest } = data;

    this.props.generateUserContactsReport(rest);
    this.props.toggleModal();
    this.props.reset();
  }

  handleCloseModal() {
    this.props.toggleModal();
    this.props.reset();
  }

  render() {
    const { showModal, handleSubmit } = this.props;

    const fields = [
      { id: 'phone_number', label: 'Phone Number' },
      { id: 'skype_id', label: 'Skype ID' },
      { id: 'slack_id', label: 'Slack ID' },
      { id: 'date_of_birth', label: 'Date of Birth' },
      { id: 'role', label: 'Role' },
      { id: 'job', label: 'Job Description' },
      { id: 'company_joined_at', label: 'Hire Date' },
      { id: 'address', label: 'Address' },
      { id: 'postal_code', label: 'Postal Code' },
      { id: 'country_id', label: 'Country' },
      { id: 'state_id', label: 'State' },
      { id: 'city_id', label: 'City' },
      { id: 'tshirt_size', label: 'T-Shirt Size' },
      { id: 'foot_size', label: 'Foot Size' },
      { id: 'emergency_name', label: 'Emergency Contact - Name' },
      { id: 'emergency_phone_number', label: 'Emergency Contact - Phone Number' },
    ];

    return (
      <Modal className="away-modal large" isOpen={showModal} toggle={this.handleCloseModal}>
        <ModalBody className="away-modal-body">
          <Fragment>
            <div className="away-modal-line">
              <h3>User Contacts</h3>
            </div>

            <Field
              className="away-dropdown-form version-2"
              id="studio_id"
              label="Studio"
              name="studio_id"
              component={Select2}
              placeholder="Studio"
              classNamePrefix="away"
              options={[{ label: 'All Studios', value: 0 }, ...this.props.studios]}
            />

            <Field
              id="check_all"
              name="check_all"
              label="Check All"
              component={Checkbox}
              onChange={e => {
                fields.forEach(field => this.props.change(field.id, e.target.checked));
              }}
            />

            <div className="away-modal-columns">
              <div className="away-modal-column">
                {fields.slice(0, Math.ceil(fields.length / 2)).map(field => (
                  <Field
                    key={field.id}
                    id={field.id}
                    name={field.id}
                    label={field.label}
                    component={Checkbox}
                  />
                ))}
              </div>
              <div className="away-modal-column">
                {fields.slice(Math.ceil(fields.length / 2)).map(field => (
                  <Field
                    key={field.id}
                    id={field.id}
                    name={field.id}
                    label={field.label}
                    component={Checkbox}
                  />
                ))}
              </div>
            </div>

            <div className="away-modal-buttons">
              <Button onClick={handleSubmit(this.handleSubmitClicked)} className="btn btn-primary">
                Generate
              </Button>
              <Button onClick={this.handleCloseModal} className="btn-link btn-grey">
                Cancel
              </Button>
            </div>
          </Fragment>
        </ModalBody>
      </Modal>
    );
  }
}

const UserContactsReportModalForm = reduxForm({
  validate,
  form: 'UserContactsReportModalForm',
  enableReinitialize: true,
})(UserContactsReportModal);

const mapDispatchToProps = dispatch => ({
  generateUserContactsReport: bindActionCreators(generateUserContactsReport, dispatch),
});

export default connect(
  null,
  mapDispatchToProps,
)(UserContactsReportModalForm);
